import missionController from "./mission_controller";
import { BaseComponent } from "../../../common/base-component.js";
import rewardIcon from "./mission_icons.js";
import {redirect} from '../../../common/helpers.js'


import { stringTruncate, DT_M_BREAK, belowBreak } from "../../../common/helpers.js";

const TITLE_MAX_LENGTH = 68;

const DURATION_STEP_COMPLETE = 2000;
const DURATION_SCROLL = DURATION_STEP_COMPLETE / 4;
const DURATION_HIGHLIGHT_COMPL = DURATION_STEP_COMPLETE / 4;
const DURATION_HIGHLIGHT_AVAIL = DURATION_STEP_COMPLETE / 4;
const DURATION_HL_AVAIL_DELAY = DURATION_HIGHLIGHT_AVAIL * 3;
let redirectInProgress = false;

const style = `
    @keyframes lightAvailable {
        0% {
            background: var(--color-brand);
        }
        20% {
            background: white;
        }
        40% {
            background: var(--color-brand);
        }
        60% {
            background: white;
        }
        80% {
            background: var(--color-brand);
        }
        100% {
            background: white;
        }
    }

    .checkbox {
        border-radius: 0.375rem;
        border: 0.125rem var(--color-control-border) solid;
        background: white;
        height: 1.375rem;
        width: 1.375rem;
        min-width: 1.375rem;
    }
    @media (min-width: ${DT_M_BREAK}) {
        .checkbox {
            align-self: flex-start;
            margin: 0.125rem 0 0 0;
        }
    }
    
    .checkbox svg path {
        transition: transform ${DURATION_HIGHLIGHT_COMPL}ms linear;
    }

    .checkbox--animate svg path {
        transform-origin: center 25%;
        transform: scaleY(2);
    }

    .checkbox--available {
        animation: lightAvailable 3s linear 1;
    }
    
    .checkbox--complete {
        background-color: var(--color-control-border);
    }

    .mission-step {
        display: flex;
        justify-content: stretch;
        flex-direction: row;
        transition: background-color 1s;
        align-items: center;
        margin: .25rem 0;
        gap: 1rem;
        background: var(--bg-page-gray);
        padding: 0.5rem 1.5rem; 
        position: relative;
    }
    .cta {
        display: flex;
        align-items: center;
        justify-content: center;

        align-self: flex-end;
        
        width: 8.875rem;
        margin: 1rem auto 0 auto;
        padding: 0.3125rem 0;

        border-radius: 1.25rem;
        
        font-size: 0.875rem;
        text-transform: uppercase;
        text-decoration: none;

        transition: background-color ${DURATION_HIGHLIGHT_COMPL}ms ease-in-out, color ${DURATION_HIGHLIGHT_COMPL}ms ease-in-out, border-color ${DURATION_HIGHLIGHT_COMPL}ms ease-in-out;
    }
    @media (min-width: ${DT_M_BREAK}) {
        .cta {
            width: 7.125rem;
            margin: 0 0 0 1.5rem;
            align-self: center;

            font-size: 0.75rem;
        }
    }

    .cta span {
        transition: opacity ${DURATION_HIGHLIGHT_COMPL/2}ms ease-in-out;
    }
    .cta--available {
        border: 0.01625rem var(--color-blue) solid;
        background-color: var(--cta-blue);
        color: white;
    }
    .cta--locked {
        border: 0.01625rem var(--color-control-border) solid;
        background-color: var(--color-bg-disabled);
        color: var(--color-fg);
        gap: 0.25rem;
    }
    .cta--complete {
        border: 0.01625rem white solid;
        background-color: white;
        color: var(--color-fg);
    }

    @media (min-width: ${DT_M_BREAK}) {
        .reward-icon {
            line-height: 1;
        }
    }

    .description {
        flex-grow: 1;
    }
    @media (max-width: ${DT_M_BREAK}) {
        .description {
            align-self: flex-start;
        }
    }
    .rulesfacts {
        font-size: 0.75rem;
    }
    @media (max-width: ${DT_M_BREAK}) {
        .rulesfacts {
            font-size: 0.75rem;
            width: 100%;
            text-align: center;
            margin: 1rem 0 0 0;
        }
    }
    .rulesfacts > a {
        color: var(--text-gray-medium);
        text-decoration: none;
    }
    .rulesfacts > span {
        margin: 0 0.5rem;
    }
    .name {
        color: var(--text-gray-dark);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.03rem;
        margin: 0 0 0.5rem 0;
        max-width: 24.75rem;
    }
    @media (min-width: ${DT_M_BREAK}) and (min-resolution: 2dppx) and (pointer: coarse) {
        .name {
            max-width: 16.75rem;
        }
    }

    .mobile-new-line {
        display: none;
    }
    
    @media (max-width: ${DT_M_BREAK}) {
        .mobile-new-line {
            width: 100%;
            display: block;
        }
        .name {
            width: 13rem;
            align-self: flex-start;
        }
        .mission-step {
            flex-wrap: wrap;
            
            margin: 0.125;
            padding: 1rem;
            align-items: baseline;
            gap: 0px 1rem;  
        }
    }
`

customElements.define(
    "mission-step",
    class extends BaseComponent {
        static observedAttributes = [
            'data-status', 
            'data-title', 
            'data-icon',
            'data-survey',
            'data-alias',
            'data-button-text-available',
            'data-button-text-locked',
            'data-button-text-completed',
            'data-button-text-expired',
        ];

        #svgCheckmark = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 16" fill="none"><path d="M2 7.05263L7.13333 14L16 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>`

        constructor() {
            super(style);

            missionController.subscribe('step_progress-complete', ( {alias, stepIndex, scrollIntoView} ) => {

                if(alias === this.dataset.alias && `${stepIndex}` === this.dataset.stepIndex) {
                    //since this step is complete, update status, scroll into view and roll animation.
                    if(scrollIntoView && belowBreak()) this.scrollIntoView({behavior:'instant', block: 'center'});
                    this.dataset.status = 'complete';
                    setTimeout(() => {
                        missionController.publish('step_feedback-complete');
                    }, DURATION_STEP_COMPLETE);
                } else if(alias === this.dataset.alias && `${stepIndex}` !== this.dataset.stepIndex) {
                    //Since some other step was completed and I am already unlocked, I should announce that I am available (as long as I am not locked).
                    if(!this.shadowRoot.querySelector('.checkbox').classList.contains('checkbox--complete')
                    && !this.shadowRoot.querySelector('.cta--locked')) {
                        this.animateUnlock();
                    }
                }
            });

            missionController.subscribe('step_progress-unlock', ( { alias, stepIndex } ) => {
                // Since I have been told to unlock myself, I will make sure that I am in view and then animate.
                if(alias === this.dataset.alias && `${stepIndex}` === this.dataset.stepIndex) {
                    if(belowBreak()) this.scrollIntoView({behavior:'instant', block: 'end'});
                    this.dataset.status = 'available';
                }
            });
        }

        animateCheckmark() {
            //Expecting `available` markup to be present
            const checkbox = this.shadowRoot.querySelector('.checkbox');
            checkbox.style.backgroundColor = 'var(--color-brand)';
            checkbox.innerHTML = this.#svgCheckmark;

            checkbox.classList.add('checkbox--animate');
            setTimeout(() => {
                checkbox.classList.remove('checkbox--animate');
                setTimeout(() => {
                    checkbox.style.backgroundColor = 'var(--color-control-border)';
                }, DURATION_HIGHLIGHT_COMPL);
            }, DURATION_HIGHLIGHT_COMPL);
        }

        animateUnlock() {
            setTimeout(() => {
                const checkbox = this.shadowRoot.querySelector('.checkbox');
                checkbox.classList.add('checkbox--available');
            }, DURATION_HL_AVAIL_DELAY);
        }

        animateButton(fromState, toState) {
            const cta = this.shadowRoot.querySelector('.cta');
            const span = cta.querySelector('span');
            span.style.opacity = 0;
            cta.classList.remove(`cta--${fromState.toLowerCase()}`);
            cta.classList.add(`cta--${toState.toLowerCase()}`);
            if(fromState === 'Locked') { cta.querySelector('svg')?.remove(); }
            setTimeout(() => {
                span.innerHTML = this.dataset[`buttonText${toState}`];
                span.style.opacity = 1;
            }, DURATION_HIGHLIGHT_COMPL);
        }
        bindClick() {
            this.shadowRoot.querySelector('.cta--available').addEventListener('click', this.handleClick.bind(this));
        }
        
        handleClick(e) {
            e.preventDefault();
            if (!redirectInProgress) {
                redirectInProgress = true;
                redirect(this.shadowRoot.querySelector('.mission-step'), e.currentTarget.href);
            }
        }

        connectedCallback(){
            super.connectedCallback();
        }

        rulesfacts() {
            if(!this.dataset.rules && !this.dataset.facts) return '';
            const rules = this.dataset.rules ? `<a href="${this.dataset.rules}" target="_blank">Official Rules</a>` : '';
            const facts = this.dataset.facts ? `<a href="${this.dataset.facts}" target="_blank">Sweepstakes Facts</a>` : '';

            return `<div class="rulesfacts">
                        ${rules}
                        ${rules && facts ? `<span>|</span>` : ''}
                        ${facts}
                    </div>`
        }

        complete(){
            return `
                <div class="mission-step">
                        <div class="checkbox checkbox--complete">
                            ${this.#svgCheckmark}
                        </div>
                        <header class="description">
                            <h4 class="name">${stringTruncate(this.dataset.title, TITLE_MAX_LENGTH)}</h4>
                            ${!isMobile ? this.rulesfacts() : ''}
                        </header>
                        <div class="reward-icon">${rewardIcon(JSON.parse(this.dataset.icon))}</div>
                        <div class="mobile-new-line"></div>
                        <a class="cta cta--complete" id="cta" aria-disabled><span>${this.dataset.buttonTextComplete}</span></a>
                        ${isMobile ? this.rulesfacts() : ''}
                </div>
            `
        }

        available({disable = false} = {}){
            const href = `href="${this.dataset.survey}"`;

            return `
                <div class="mission-step">
                        <div class="checkbox"></div>
                        <header class="description">
                            <h4 class="name">${stringTruncate(this.dataset.title, TITLE_MAX_LENGTH)}</h4>
                            ${!isMobile ? this.rulesfacts() : ''}
                        </header>
                        <div class="reward-icon">${rewardIcon(JSON.parse(this.dataset.icon))}</div>
                        <div class="mobile-new-line"></div>
                        <a class="cta cta--available" id="cta" ${disable ? '' : href}><span>${this.dataset.buttonTextAvailable}</span></a>
                        ${isMobile ? this.rulesfacts() : ''}
                </div>
            `
        }

        locked({enable = false} = {}){
            const href = `href="${this.dataset.survey}"`;

            return `
                <div class="mission-step">
                        <div class="checkbox"></div>
                        <header class="description">
                            <h4 class="name">${stringTruncate(this.dataset.title, TITLE_MAX_LENGTH)}</h4>
                            ${!isMobile ? this.rulesfacts() : ''}
                        </header>
                        <div class="reward-icon">${rewardIcon(JSON.parse(this.dataset.icon))}</div>
                        <div class="mobile-new-line"></div>
                        <a class="cta cta--locked" id="cta" ${enable ? href : 'aria-disabled'}>
                            <span>${this.dataset.buttonTextLocked}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10005 6.30006V5.32176C5.10005 4.15084 5.56228 3.02675 6.38681 2.1971C7.21154 1.36725 8.3313 0.900024 9.50005 0.900024C10.6688 0.900024 11.7886 1.36725 12.6133 2.1971C13.4378 3.02675 13.9001 4.15084 13.9001 5.32176V6.30006H14.9C15.3703 6.29747 15.8244 6.45426 16.172 6.73925C16.3365 6.87635 16.4681 7.0422 16.5589 7.22666C16.6497 7.41112 16.6977 7.61031 16.7 7.81205V15.588C16.6977 15.7897 16.6497 15.9889 16.5589 16.1734C16.4681 16.3578 16.3365 16.5237 16.172 16.6608C15.8244 16.9458 15.3703 17.1026 14.9 17.1H4.10005C3.62981 17.1026 3.17571 16.9458 2.82805 16.6608C2.6636 16.5237 2.53195 16.3578 2.44117 16.1734C2.35038 15.9889 2.30236 15.7897 2.30005 15.588V7.81205C2.30236 7.61031 2.35038 7.41112 2.44117 7.22666C2.53195 7.0422 2.6636 6.87635 2.82805 6.73925C3.17571 6.45426 3.62981 6.29747 4.10005 6.30006H5.10005ZM7.66354 3.46594C8.15157 2.97488 8.81229 2.70002 9.50005 2.70002C10.1878 2.70002 10.8485 2.97488 11.3366 3.46594C11.8248 3.95719 12.1001 4.62464 12.1001 5.32176V6.30006H6.90005V5.32176C6.90005 4.62464 7.17532 3.95719 7.66354 3.46594ZM10.3 14.22V11.8656C10.543 11.7394 10.7449 11.5578 10.8853 11.3392C11.0257 11.1206 11.0998 10.8726 11.1 10.62C11.1 10.2381 10.9315 9.87185 10.6314 9.6018C10.3314 9.33175 9.9244 9.18004 9.50005 9.18004C9.0757 9.18004 8.66874 9.33175 8.36868 9.6018C8.06862 9.87185 7.90005 10.2381 7.90005 10.62C7.90034 10.8726 7.9744 11.1206 8.11482 11.3392C8.25523 11.5578 8.45706 11.7394 8.70005 11.8656V14.22C8.70005 14.411 8.78433 14.5941 8.93436 14.7291C9.08439 14.8642 9.28788 14.94 9.50005 14.94C9.71222 14.94 9.91571 14.8642 10.0657 14.7291C10.2158 14.5941 10.3 14.411 10.3 14.22Z" fill="#636565"/>
                            </svg>
                        </a>
                        ${isMobile ? this.rulesfacts() : ''}
                </div>
            `
        }

        render(name, oldValue, newValue) {

            if(name === 'data-status' && oldValue === 'available' && newValue === 'complete') {
                setTimeout(() => {
                    this.animateCheckmark();
                    this.animateButton('Available', 'Complete');
                }, DURATION_SCROLL);
                return this.available({disable: true}); //Start from available, then animate to complete - We disable the button right away though.
            } else if(name === 'data-status' && oldValue === 'locked' && newValue === 'available') {
                setTimeout(() => {
                    this.animateUnlock();
                    this.animateButton('Locked', 'Available');
                    this.bindClick()
                }, DURATION_SCROLL);
                return this.locked({enable: true}); //Start from locked, then animate to available - We enable the button right away though.
            } else {
                switch(this.dataset.status){
                    case "complete":
                        return this.complete();
                    case "locked":
                        return this.locked();
                    case "available":
                        setTimeout(() => {
                            this.bindClick();
                        }, DURATION_SCROLL);
                    
                        return this.available();
                    default:
                        return `<div>Something went  Wrong</div>`
                }
            }
            

        }
    },
);